import { BaseDTO, GlossaryId, DateTime, Date, Number } from '../base';
import { LeasedLineDTO } from '../leasedLine';
import { MicrowaveDTO } from '../microwave';
import { BlueMoonDTO } from '../blueMoon';
import { FiberDTO } from '../fiber';

// ----------------

export namespace TXDTO {
  export type $ = {
    is_production_plan_readiness_locked: boolean;
    production_year_estimation: Number;
    production_plan_readiness: string | null;
    delivery_forecast_public: Date;
    mwingz_code_from_carto: string | null;
    family_mp_projected: GlossaryId;
    family_bm_projected: GlossaryId;
    overall_readiness: string | null;
    conso_year_mwingz: number | null;
    family_bm_legacy: GlossaryId;
    radio_site_type: GlossaryId;
    microwave_data: MicrowaveDTO.MicrowaveDTO | null;
    cluster_mwingz: GlossaryId;
    current_techno: GlossaryId;
    double_stream: GlossaryId;
    target_techno: GlossaryId;
    radio_status: GlossaryId;
    leased_line: LeasedLineDTO.$ | null;
    is_archived: boolean;
    temp_techno: GlossaryId;
    mwingz_code: string;
    update_date: DateTime;
    family_temp: GlossaryId;
    deleted_at: DateTime;
    deleted_by: null;
    is_deleted: boolean;
    fiber_data: FiberDTO | null;
    blue_moon: BlueMoonDTO.$ | null;
    bm_status: GlossaryId;
    microwave: GlossaryId;
    longitude: Number;
    originals: GlossaryId[];
    is_draft: boolean;
    latitude: Number;
    address: string;
    site_id: string;
    status: GlossaryId;
    family: GlossaryId;
    target: GlossaryId;
    region: string;
    city: GlossaryId;
    zip: Number;
    id: number;

    candidate_2g: string;
    candidate_3g: string;
    candidate_4g: string;
    candidate_5g: string;

    site_2g: string;
    site_3g: string;
    site_4g: string;
    site_5g: string;
  };

  export type PaginatedList = BaseDTO.PaginatedList<$>;

  // --------

  export type ZipCode = {
    city: string;
    code: number;
  };

  export type ZipCodePaginatedList = BaseDTO.PaginatedList<ZipCode>;
}
