import { BaseDO, DateTime, Date, Number, GlossaryId } from '../base';

// ----------------

export namespace BlueMoonDO {
  export type $ = {
    ranSharingFeasibleOnTime: string;
    statusForRanSharing: string;
    foreseenRelocName: string;
    swapRelocComments: string;
    deliveryForecast: Date;
    obeLegacyConfig: string;
    obeLegacyLayer: string;
    statusUpdate: string;
    forecastInfo: string;
    fctE2eIsDate: boolean;
    alternative: string;
    capacityY1: string;
    isArchived: boolean;
    remainWeek: Number;
    weekNumber: Number;
    fctE2eWeek: string;
    readiness: string;
    targetTx: GlossaryId;
    created: DateTime;
    updated: DateTime;
    fctE2e: string | null;
    dDay: DateTime;
    pri: string;
    id: number;
    tx: number;
  };

  export type PaginatedList = BaseDO.PaginatedList<$>;
}
