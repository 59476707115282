// Entities

import { BlueMoonDO, BlueMoonDTO, blueMoonMapper } from './';

// ----------------

export const blueMoonAnalyzer = {
  getHiddenFields(permissions: string[]) {
    const hiddenFields: Array<keyof BlueMoonDTO.$> = [
      'ran_sharing_feasible_on_time',
      'delivery_forecast_general',
      'status_for_ran_sharing',
      'foreseen_reloc_name',
      'swap_reloc_comments',
      'obe_legacy_config',
      'obe_legacy_layer',
      'fct_e2e_is_date',
      'status_update',
      'forecast_info',
      'fct_e2e_week',
      'capacity_y1',
      'remain_week',
      'week_number',
      'alternative',
      'target_tx',
      'readiness',
      'fct_e2e',
      'd_day',
      'pri',
    ];

    const dtoKeysMap = blueMoonMapper.getDTOKeysMap();

    return hiddenFields
      .filter((field) => !permissions.some((permission) => permission === `can_user_view_bluemoon_column_${field}`))
      .map((field) => dtoKeysMap[field])
      .filter((field) => field) as Array<keyof BlueMoonDO.$>;
  },
};
