import { TXDTO, TXDO, txAnalyzer } from './';
import { leasedLineMapper } from '../leasedLine';
import { microwaveMapper } from '../microwave';
import { blueMoonMapper } from '../blueMoon';
import { fiberMapper } from '../fiber';

// Utils

import { createKeysMappers } from '../base/utils/createKeysMappers';

// ----------------

export const txMapper = {
  toDO(data: TXDTO.$): TXDO.$ {
    const dataDO: TXDO.$ = {
      isProductionPlanReadinessLocked: data.is_production_plan_readiness_locked,
      productionYearEstimation: data.production_year_estimation,
      productionPlanReadiness: data.production_plan_readiness,
      deliveryForecastPublic: data.delivery_forecast_public,
      mwingzCodeFromCarto: data.mwingz_code_from_carto,
      familyMpProjected: data.family_mp_projected,
      familyBmProjected: data.family_bm_projected,
      overallReadiness: data.overall_readiness,
      consoYearMwingz: data.conso_year_mwingz,
      familyBmLegacy: data.family_bm_legacy,
      leasedLineData: data.leased_line ? leasedLineMapper.toDO(data.leased_line) : null,
      radioSiteType: data.radio_site_type,
      microwaveData: data.microwave_data ? microwaveMapper.toDO(data.microwave_data) : null,
      clusterMwingz: data.cluster_mwingz,
      currentTechno: data.current_techno,
      doubleStream: data.double_stream,
      targetTechno: data.target_techno,
      radioStatus: data.radio_status,
      isArchived: data.is_archived,
      tempTechno: data.temp_techno,
      mwingzCode: data.mwingz_code,
      updateDate: data.update_date,
      familyTemp: data.family_temp,
      deletedAt: data.deleted_at,
      deletedBy: null,
      isDeleted: data.is_deleted,
      fiberData: data.fiber_data ? fiberMapper.toDO(data.fiber_data) : null,
      microwave: data.microwave,
      longitude: data.longitude,
      originals: data.originals,
      blueMoon: data.blue_moon ? blueMoonMapper.toDO(data.blue_moon) : null,
      category: 'fiber',
      bmStatus: data.bm_status,
      latitude: data.latitude,
      isDraft: data.is_draft,
      address: data.address,
      siteId: data.site_id,
      status: data.status,
      family: data.family,
      target: data.target,
      region: data.region,
      city: data.city,
      zip: data.zip,
      id: data.id,

      candidate2g: data.candidate_2g,
      candidate3g: data.candidate_3g,
      candidate4g: data.candidate_4g,
      candidate5g: data.candidate_5g,

      site2g: data.site_2g,
      site3g: data.site_3g,
      site4g: data.site_4g,
      site5g: data.site_5g,
    };

    dataDO.category = txAnalyzer.getCategory(dataDO);

    return dataDO;
  },

  // --------

  toDTO(data: Partial<TXDO.$>): Partial<TXDTO.$> {
    // prettier-ignore
    return {
      is_production_plan_readiness_locked: data.isProductionPlanReadinessLocked,
      production_year_estimation: data.productionYearEstimation,
      production_plan_readiness: data.productionPlanReadiness,
      family_mp_projected: data.familyMpProjected,
      family_bm_projected: data.familyBmProjected,
      overall_readiness  : data.overallReadiness,
      conso_year_mwingz  : data.consoYearMwingz,
      family_bm_legacy   : data.familyBmLegacy,
      radio_site_type    : data.radioSiteType,
      cluster_mwingz     : data.clusterMwingz,
      current_techno     : data.currentTechno,
      double_stream      : data.doubleStream,
      target_techno      : data.targetTechno,
      radio_status       : data.radioStatus,
      is_archived        : data.isArchived,
      temp_techno        : data.tempTechno,
      mwingz_code        : data.mwingzCode,
      family_temp        : data.familyTemp,
      bm_status          : data.bmStatus,
      microwave          : data.microwave,
      longitude          : data.longitude,
      originals          : data.originals,
      is_draft           : data.isDraft,
      latitude           : data.latitude,
      address            : data.address,
      site_id            : data.siteId,
      status             : data.status,
      family             : data.family,
      target             : data.target,
      region             : data.region,
      city               : data.city,
      zip                : data.zip,
      id                 : data.id,
    
      candidate_2g       : data.candidate2g,
      candidate_3g       : data.candidate3g,
      candidate_4g       : data.candidate4g,
      candidate_5g       : data.candidate5g,
    
      site_2g            : data.site2g,
      site_3g            : data.site3g,
      site_4g            : data.site4g,
      site_5g            : data.site5g,
    };
  },

  // --------

  zipCodeToDO(data: TXDTO.ZipCode): TXDO.ZipCode {
    return { code: data.code, city: data.city };
  },

  // --------

  // prettier-ignore
  ...createKeysMappers<TXDTO.$, TXDO.$>([
    ['is_production_plan_readiness_locked', 'isProductionPlanReadinessLocked'],
    ['production_year_estimation', 'productionYearEstimation'],
    ['production_plan_readiness', 'productionPlanReadiness'],
    ['delivery_forecast_public', 'deliveryForecastPublic'],
    ['mwingz_code_from_carto', 'mwingzCodeFromCarto'],
    ['family_mp_projected', 'familyMpProjected'],
    ['family_bm_projected', 'familyBmProjected'],
    ['overall_readiness'  , 'overallReadiness'],
    ['conso_year_mwingz'  , 'consoYearMwingz'],
    ['family_bm_legacy'   , 'familyBmLegacy'],
    ['radio_site_type'    , 'radioSiteType'],
    ['cluster_mwingz'     , 'clusterMwingz'],
    ['current_techno'     , 'currentTechno'],
    ['double_stream'      , 'doubleStream'],
    ['target_techno'      , 'targetTechno'],
    ['radio_status'       , 'radioStatus'],
    ['temp_techno'        , 'tempTechno'],
    ['mwingz_code'        , 'mwingzCode'],
    ['family_temp'        , 'familyTemp'],
    ['bm_status'          , 'bmStatus'],
    ['microwave'          , 'microwave'],
    ['longitude'          , 'longitude'],
    ['originals'          , 'originals'],
    ['is_draft'           , 'isDraft'],
    ['latitude'           , 'latitude'],
    ['address'            , 'address'],
    ['site_id'            , 'siteId'],
    ['status'             , 'status'],
    ['family'             , 'family'],
    ['target'             , 'target'],
    ['region'             , 'region'],
    ['city'               , 'city'],
    ['zip'                , 'zip'],
    ['id'                 , 'id'],

    ['candidate_2g', 'candidate2g'],
    ['candidate_3g', 'candidate3g'],
    ['candidate_4g', 'candidate4g'],
    ['candidate_5g', 'candidate5g'],

    ['site_2g', 'site2g'],
    ['site_3g', 'site3g'],
    ['site_4g', 'site4g'],
    ['site_5g', 'site5g'],
  ]),
};
