import { BaseDTO, DateTime, Date, Number, GlossaryId } from '../base';

// ----------------

export namespace BlueMoonDTO {
  export type $ = {
    ran_sharing_feasible_on_time: string;
    delivery_forecast_general: Date;
    status_for_ran_sharing: string;
    foreseen_reloc_name: string;
    swap_reloc_comments: string;
    obe_legacy_config: string;
    obe_legacy_layer: string;
    fct_e2e_is_date: boolean;
    status_update: string;
    forecast_info: string;
    transmission: number;
    fct_e2e_week: string;
    capacity_y1: string;
    is_archived: boolean;
    remain_week: Number;
    week_number: Number;
    alternative: string;
    created_at: DateTime;
    updated_at: DateTime;
    target_tx: GlossaryId;
    readiness: string;
    fct_e2e: string | null;
    d_day: DateTime;
    pri: string;
    id: number;
  };

  export type PaginatedList = BaseDTO.PaginatedList<$>;
}
