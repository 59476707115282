import { globalObj } from '../../config/app';
import { RootState } from '../../redux';

// Utils

import hasPermissions from '../../utils/hasPermissions/hasPermissions';

// Entities

import { GlossaryFamilyCodes, GlossaryRadioStatusCodes, GlossaryStatusCodes } from '../glossary';
import { getGlossaryValueByCode } from '../glossary/utils/getGlossaryValueByCode';
import { TXDO, TXDTO, txMapper } from './';
import { UserCommon } from '../user';

// ----------------

export const txAnalyzer = {
  getCategory(data: TXDO.$, defFamilyKey: keyof TXDO.$ = 'family'): TXDO.Category {
    const state: RootState = globalObj.reduxStore?.getState();
    const familyGlossary = state.glossary.glossaries.family;

    const categoryValues: Record<TXDO.Category, Array<number>> = {
      leasedLine: [],
      microwave: [],
      fiber: [],
    };

    categoryValues.leasedLine = getGlossaryValueByCode([GlossaryFamilyCodes.leasedLine], familyGlossary);
    categoryValues.microwave = getGlossaryValueByCode([GlossaryFamilyCodes.microwave], familyGlossary);
    categoryValues.fiber = getGlossaryValueByCode([GlossaryFamilyCodes.fttn, GlossaryFamilyCodes.ftts], familyGlossary);
    const [ongoingValue] = getGlossaryValueByCode([GlossaryFamilyCodes.ongoing], familyGlossary);

    let familyKey: keyof TXDO.$ = defFamilyKey;

    switch (true) {
      case data.family === ongoingValue: {
        familyKey = 'familyMpProjected';
        break;
      }
    }

    return Object.keys(categoryValues).find((key) => categoryValues[key].some((value) => value === data[familyKey])) as TXDO.Category;
  },

  // --------

  canEdit(data: TXDO.$) {
    const state: RootState = globalObj.reduxStore?.getState();
    return data.radioStatus !== getGlossaryValueByCode([GlossaryRadioStatusCodes.dismantled], state.glossary.glossaries.radioStatus)[0];
  },

  // --------

  canMove(data: TXDO.$) {
    const state: RootState = globalObj.reduxStore?.getState();

    if (data.target || data.isArchived) {
      return false;
    }

    if (data.status === getGlossaryValueByCode([GlossaryStatusCodes.up], state.glossary.glossaries.status)[0]) {
      return true;
    }

    return false;
  },

  // --------

  canCancelMove(data: TXDO.$) {
    return !!data.target && !data.isArchived;
  },

  // --------

  canCloseMove(data: TXDO.$) {
    return !!data.target && !data.isArchived;
  },

  // --------

  canDismantle(data: TXDO.$) {
    const state: RootState = globalObj.reduxStore?.getState();
    return (
      !data.isArchived &&
      data.radioStatus === getGlossaryValueByCode([GlossaryRadioStatusCodes.live], state.glossary.glossaries.radioStatus)[0] &&
      data.status === getGlossaryValueByCode([GlossaryStatusCodes.up], state.glossary.glossaries.status)[0]
    );
  },

  // --------

  canLock(data: TXDO.$) {
    if (hasPermissions([UserCommon.Permission.COMMON_LOCK_TX]) && !data.isProductionPlanReadinessLocked) {
      return true;
    }

    return false;
  },

  // --------

  canUnlock(data: TXDO.$) {
    if (hasPermissions([UserCommon.Permission.COMMON_UNLOCK_TX]) && data.isProductionPlanReadinessLocked) {
      return true;
    }

    return false;
  },

  // --------

  canBulkActions() {
    if (hasPermissions([UserCommon.Permission.COMMON_UNLOCK_TX]) || hasPermissions([UserCommon.Permission.COMMON_LOCK_TX])) {
      return true;
    }

    return false;
  },

  // --------

  getCommonTableSelectedRowActions(data: TXDO.$[], ids: string[]) {
    const commonTableSelectedRowActions: Record<TXDO.TableSelectedRowAction, boolean> = {
      productionPlanReadinessUnlock: false,
      productionPlanReadinessLock: false,
    };
    const items = data.filter((item) => ids.includes(String(item.id)));

    if (items.every((item) => txAnalyzer.canUnlock(item))) {
      commonTableSelectedRowActions.productionPlanReadinessUnlock = true;
    }

    if (items.every((item) => txAnalyzer.canLock(item))) {
      commonTableSelectedRowActions.productionPlanReadinessLock = true;
    }

    return commonTableSelectedRowActions;
  },

  // --------

  getHiddenFields(permissions: string[]) {
    const hiddenFields: Array<keyof TXDTO.$> = [
      'production_year_estimation',
      'production_plan_readiness',
      'delivery_forecast_public',
      'mwingz_code_from_carto',
      'family_mp_projected',
      'family_bm_projected',
      'overall_readiness',
      'conso_year_mwingz',
      'radio_site_type',
      'cluster_mwingz',
      'current_techno',
      'double_stream',
      'target_techno',
      'radio_status',
      'temp_techno',
      'mwingz_code',
      'bm_status',
      'microwave',
      'longitude',
      'latitude',
      'address',
      'site_id',
      'status',
      'family',
      'target',
      'region',
      'city',
      'zip',
      'candidate_2g',
      'candidate_3g',
      'candidate_4g',
      'candidate_5g',
      'site_2g',
      'site_3g',
      'site_4g',
      'site_5g',
    ];

    const dtoKeysMap = txMapper.getDTOKeysMap();

    return hiddenFields
      .filter((field) => !permissions.some((permission) => permission === `can_user_view_column_tx_${field}`))
      .map((field) => dtoKeysMap[field])
      .filter((field) => field !== undefined) as Array<keyof TXDO.$>;
  },
};
