const labels = {
  ranSharingFeasibleOnTime: 'RAN sharing feasible on time',
  statusForRanSharing: 'Status for RAN sharing',
  foreseenRelocName: 'Foreseen reloc name',
  swapRelocComments: 'SWAP / Reloc / Comments',
  deliveryForecast: 'Delivery Forecast',
  obeLegacyConfig: 'OBE legacy config',
  obeLegacyLayer: 'OBE legacy layer',
  statusUpdate: 'Update status',
  forecastInfo: 'FCT Info',
  alternative: 'Alternative',
  capacityY1: 'Capacity Y1',
  remainWeek: 'Remain week',
  weekNumber: 'Week number',
  fctE2eWeek: 'FCT E2E Week',
  readiness: 'Readiness',
  targetTx: 'Target TX',
  fctE2e: 'FCT E2E',
  dDay: 'D-day',
  pri: 'PRI',
};

// --------

const blueMoonEN = {
  labels,
};

export default blueMoonEN;
